<template>
  <div class="date-time-picker">
    <div class="field-label">
      {{ placeholder }}
    </div>
    <v-layout wrap id="fields-content">
      <v-flex xs12 sm6>
        <DatePicker
          ref="datePickerFrom"
          :required="required"
          :value="(parsedParamValue || {}).From"
          :minDate="minAllowedDateTimeFrom"
          :maxDate="maxAllowedDateTime"
          @input="(value) => onDateFromChange(value)"
        ></DatePicker>
      </v-flex>
      <v-flex xs12 sm6>
        <TimePicker
          ref="timePickerFrom"
          :value="(parsedParamValue || {}).From"
          :required="required"
          :allowedMinutes="allowedMinutes"
          :minTime="isTodayDateSelected ? minAllowedDateTimeFrom : null"
          :maxTime="null"
          @input="(value) => onTimeFromChange(value)"
        ></TimePicker>
      </v-flex>
    </v-layout>
    <v-layout wrap id="fields-content">
      <v-flex xs12 sm6>
        <DatePicker
          ref="datePickerTo"
          :required="required"
          :value="(parsedParamValue || {}).To"
          :minDate="minAllowedDateTime"
          :maxDate="maxAllowedDateTimeTo"
          @input="(value) => onDateToChange(value)"
        ></DatePicker>
      </v-flex>
      <v-flex xs12 sm6>
        <TimePicker
          ref="timePickerTo"
          :value="(parsedParamValue || {}).To"
          :required="required"
          :allowedMinutes="allowedMinutes"
          :minTime="
            isTodayDateToSelected || isSameDaySelected
              ? minAllowedDateTime
              : null
          "
          :maxTime="
            isTodayDateToSelected || isSameDaySelected
              ? maxAllowedDateTimeTo
              : null
          "
          @input="(value) => onTimeToChange(value)"
        ></TimePicker>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import moment from "@/plugins/moment";

import DatePicker from "@/components/Shared/DatePicker";
import TimePicker from "@/components/Shared/TimePicker";

import abstractField from "@/utils/AbstractField";

export default {
  name: "DatetimeFromToPicker",
  components: { DatePicker, TimePicker },
  mixins: [abstractField],
  props: {
    paramValue: String,
    mode: Number,
    attribute: Object,
  },
  data() {
    return {
      parsedParamValue: this.paramValue ? JSON.parse(this.paramValue) : null,
      minAllowedDateTime:
        this.attribute && this.attribute.allowDateType === "FUTURE"
          ? moment().add(5, "minutes")
          : null,
      maxAllowedDateTime:
        this.attribute && this.attribute.allowDateType === "PAST"
          ? moment().add(10, "minutes")
          : null,
      minAllowedDateTimeFrom:
        this.attribute && this.attribute.allowDateType === "FUTURE"
          ? moment().add(5, "minutes")
          : null,
      maxAllowedDateTimeTo:
        this.attribute && this.attribute.allowDateType === "PAST"
          ? moment().add(10, "minutes")
          : null,
    };
  },
  watch: {
    paramValue: {
      handler(newVal, oldVal) {
        const parseNewVal = newVal ? JSON.parse(newVal) : {};
        const parseOldVal = oldVal ? JSON.parse(oldVal) : {};
        this.parsedParamValue = JSON.parse(newVal);

        if (parseOldVal && parseOldVal.From !== undefined) {
          if (parseNewVal.From !== parseOldVal.From) {
            this.updateMinAllowedDateTime();
          } else if (parseNewVal.To !== parseOldVal.To) {
            this.updateMaxAllowedDateTime();
          }
        } else if (parseNewVal.To) {
          this.updateMaxAllowedDateTime();
        } else if (parseNewVal.From) {
          this.updateMinAllowedDateTime();
        }
      },
    },
  },
  computed: {
    minuteFrom() {
      return this.parsedParamValue && this.parsedParamValue.From
        ? moment(this.parsedParamValue.From).minute()
        : moment().minute();
    },
    hourFrom() {
      return this.parsedParamValue && this.parsedParamValue.From
        ? moment(this.parsedParamValue.From).hour()
        : moment().hour();
    },
    dayFrom() {
      return this.parsedParamValue && this.parsedParamValue.From
        ? moment(this.parsedParamValue.From).date()
        : moment().date();
    },
    monthFrom() {
      return this.parsedParamValue && this.parsedParamValue.From
        ? moment(this.parsedParamValue.From).month()
        : moment().month();
    },
    yearFrom() {
      return this.parsedParamValue && this.parsedParamValue.From
        ? moment(this.parsedParamValue.From).year()
        : moment().year();
    },
    minuteTo() {
      return this.parsedParamValue
        ? moment(this.parsedParamValue.To).minute()
        : 0;
    },
    hourTo() {
      return this.parsedParamValue
        ? moment(this.parsedParamValue.To).hour()
        : 0;
    },
    dayTo() {
      return this.parsedParamValue
        ? moment(this.parsedParamValue.To).date()
        : moment().date();
    },
    monthTo() {
      return this.parsedParamValue
        ? moment(this.parsedParamValue.To).month()
        : moment().month();
    },
    yearTo() {
      return this.parsedParamValue
        ? moment(this.parsedParamValue.To).year()
        : moment().year();
    },
    allowedMinutes() {
      if (this.mode === 15) {
        return [0, 15, 30, 45];
      } else if (this.mode === 30) {
        return [0, 30];
      }

      return undefined;
    },
    isTodayDateSelected() {
      return moment((this.parsedParamValue || {}).From).isSame(moment(), "day");
    },
    isTodayDateToSelected() {
      return moment((this.parsedParamValue || {}).To).isSame(moment(), "day");
    },
    isSameDaySelected() {
      return moment((this.parsedParamValue || {}).From).isSame(
        moment(moment((this.parsedParamValue || {}).To)),
        "day"
      );
    },
  },
  methods: {
    updateMaxAllowedDateTime() {
      this.maxAllowedDateTime =
        this.attribute && this.attribute.allowDateType === "PAST"
          ? this.parsedParamValue && this.parsedParamValue.To !== undefined
            ? moment(this.parsedParamValue.To).add(5, "minutes")
            : moment()
          : this.attribute &&
            this.attribute.allowDateType === "FUTURE" &&
            this.parsedParamValue &&
            this.parsedParamValue.To !== undefined
          ? moment(this.parsedParamValue.To)
          : null;
    },
    updateMinAllowedDateTime() {
      this.minAllowedDateTime =
        this.attribute && this.attribute.allowDateType === "FUTURE"
          ? this.parsedParamValue && this.parsedParamValue.From !== undefined
            ? moment(this.parsedParamValue.From).add(10, "minutes")
            : moment()
          : this.attribute &&
            this.attribute.allowDateType === "PAST" &&
            this.parsedParamValue &&
            this.parsedParamValue.From !== undefined
          ? moment(this.parsedParamValue.From)
          : null;
    },
    onDateFromChange(newDate) {
      let date = null;
      if (newDate) {
        date = moment(newDate)
          .set("hour", this.hourFrom)
          .set("minute", this.minuteFrom)
          .set("second", this.withSeconds ? this.second : 0)
          .format("YYYY-MM-DDTHH:mm:ss");
      }
      const newValue = { From: date, To: (this.parsedParamValue || {}).To };
      this.$emit("onChange", {
        id: this.id,
        value: JSON.stringify(newValue),
      });
    },
    onDateToChange(newDate) {
      let date = null;
      if (newDate) {
        date = moment(newDate)
          .set("hour", this.hourTo)
          .set("minute", this.minuteTo)
          .set("second", this.withSeconds ? this.second : 0)
          .add(5, "minutes")
          .format("YYYY-MM-DDTHH:mm:ss");
      }
      const newValue = { From: (this.parsedParamValue || {}).From, To: date };
      this.$emit("onChange", {
        id: this.id,
        value: JSON.stringify(newValue),
      });
    },
    onTimeFromChange(newTime) {
      const date = newTime
        ? moment(newTime)
            .set("year", this.yearFrom)
            .set("month", this.monthFrom)
            .set("date", this.dayFrom)
            .format("YYYY-MM-DDTHH:mm:ss")
        : null;

      const newValue = { From: date, To: (this.parsedParamValue || {}).To };
      this.$emit("onChange", {
        id: this.id,
        value: JSON.stringify(newValue),
      });
    },
    onTimeToChange(newTimeTo) {
      const date = newTimeTo
        ? moment(newTimeTo)
            .set("year", this.yearTo)
            .set("month", this.monthTo)
            .set("date", this.dayTo)
            .format("YYYY-MM-DDTHH:mm:ss")
        : null;
      const newValue = { From: (this.parsedParamValue || {}).From, To: date };
      this.$emit("onChange", {
        id: this.id,
        value: JSON.stringify(newValue),
      });
    },
    checkIsFieldValid() {
      //nadpisane z mixina AbstractFields
      this.$refs["datePickerFrom"].$v.$touch();
      this.$refs["timePickerFrom"].$v.$touch();
      this.$refs["datePickerTo"].$v.$touch();
      this.$refs["timePickerTo"].$v.$touch();
      return (
        !this.$refs["datePickerFrom"].$v.$invalid &&
        !this.$refs["timePickerFrom"].$v.$invalid &&
        !this.$refs["datePickerTo"].$v.$invalid &&
        !this.$refs["timePickerTo"].$v.$invalid
      );
    },
  },
};
</script>

<style scope lang="scss">
.date-time-field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .date-field,
  .time-field {
    padding: 0 10px;
  }
}
</style>
